/* General styles */

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: black;
  background: #fff;
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: black;
  font-family: 'Poppins', sans-serif;
}

a, a:hover, a:active, a:focus {
  text-decoration: none;
  color: darkgoldenrod;
  font-size: 16px;
}

/* Button reset */
button {
  background: transparent !important;
  border: none !important;
  background-color:goldenrod !important;
  color: white !important; 
  border: none !important;
  padding: 10px 20px !important; 
  font-size: 16px !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  transition: background-color 0.3s ease !important; 
}



/* Hero section */

#hero {
  background-image: url("/public/assets/imgs/hero2.jpg");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  height: 100vh; 
  color: white; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px; 
}

#hero h1 {
  font-size: 3rem; 
  margin-bottom: 20px; 
  color: white; 
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

#hero p {
  font-size: 1.2rem; 
  max-width: 600px; 
  margin: 0; 
}

@media (max-width: 768px) {
  #hero{
    background-size: 250%;
  }
  #hero h1 {
    
    font-size: 2rem; 
  }

  #hero p {
    font-size: 1rem; 
  }
}

@media (max-width: 480px) {
 
  #hero h1 {
    font-size: 1.5rem;
  }

  #hero p {
    font-size: 0.9rem;
  }
}

/* About section */


#about {
  position: relative;
  padding-top: 100px;
  padding-bottom: 50px;
  background-image: url("/public/assets/imgs/dust1.jpg");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

#about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4); 
  z-index: 1; 
}

#about > * {
  position: relative; 
  z-index: 2; 
}

#about h2 {
  text-align: center; 
  margin-bottom: 100px;
}

#about .about-content {
  padding-left: 100px;
  text-align: left; 
  
}

#about h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: goldenrod; 
  padding-left: 100px;
}

#about ul {
  padding-left: 100px;
}

#about ul li {
  line-height: 1.6;
  margin-bottom: 10px; 
}

.about-image {
  max-width: 100%; 
  height: auto; 
  margin-bottom: 20px;
  border-radius: 10px; 
}

@media (max-width: 768px) {

  #about {
    position: relative;
    background-image: url("/public/assets/imgs/dust2.jpg");
    background-size: cover; 
    background-position: top left; 
    
  }

  #about::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4); 
    z-index: 1; 
  }
  
  #about > * {
    position: relative; 
    z-index: 2; 
  }
  
  #about .about-content {
    padding-left: 0;
    padding-top: 15px;
    text-align: center; 
  }

  #about h4,
  #about ul {
    padding-left: 0; 
  }
}

/* concept section */

#concept {
  position: relative;
  background-image: url("/public/assets/imgs/dust1.jpg");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  padding-top: 20px;
  padding-bottom: 50px;
}

#concept::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4); 
  z-index: 1; 
}

#concept > * {
  position: relative; 
  z-index: 2; 
}

#concept h2 {
  text-align: center; 
  margin-top: 100px;
  margin-bottom: 100px;
}

#concept p {
  font-size: 16px;
  line-height: 1.5;
  padding-right: 100px;
  text-align: left;
}

#concept .row {
  display: flex;
  align-items: flex-start;
}

#concept .col-md-8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.concept-img {
  max-width: 100%; 
  height: auto; 
  margin-bottom: 20px;
  border-radius: 10px; 
}

#concept button {
  max-width: fit-content;
}

@media (max-width: 768px) {

  #concept {
    position: relative;
    background-image: url("/public/assets/imgs/dust2.jpg");
    background-size: cover; 
    background-position: top left; 
    
  }

  #concept::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4); 
    z-index: 1; 
  }
  
  #concept > * {
    position: relative; 
    z-index: 2; 
  }

  #concept .row {
    flex-direction: column-reverse; 
    align-items: center;
  }

  #concept p {
    padding: 0; 
    text-align: center;
  }

  #concept h2 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .concept-img {
    margin-bottom: 35px; 
  }

  #concept button {
    margin: 20px auto 0 auto; 
    display: block;

  }
}

/* contact section */

#contact {
  background-image: url("/public/assets/imgs/hero1.jpg");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  min-height: 140vh; 
  padding: 50px 10px 0; 
}

#contact h2 {
  margin-top: 50px;
  margin-bottom: 80px !important; 
}

#contact .form-group {
  margin-bottom: 15px; 
}

#contact .contact-info {
  padding: 20px;
  background-color:  rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

#contact .contact-info h4 {
  margin-top: 20px;
  color: goldenrod; 
}

#contact .social-icons {
  display: flex;
  gap: 10px; 
  margin-top: 20px;
}

#contact .social-icon {
  font-size: 20px;
  color: #333;
}

#contact .social-icon:hover {
  color: goldenrod;
}

.email-link {
  color: gold;
}


@media (max-width: 768px) {
  #contact {
    min-height: 220vh; 
  }

  #contact h2 {
    font-size: 1.8rem;
    margin-bottom: 50px; 
  }

  #contact .contact-info {
    margin-top: 20px;
    
    
  }

  #contact .contact-info h4 {
    font-size: 1.1rem; 
  }

  #contact .contact-info p {
    font-size: 0.9rem; 
  }
}

/* breath page */

.vocalbreath-atem,
.vocalbreath-stimmcoaching {
  padding: 100px 0 0px;
}

.vocalbreath-atem {
  position: relative;
  background-image: url("/public/assets/imgs/dust1.jpg");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

.vocalbreath-atem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4); 
  z-index: 1; 
}

.vocalbreath-atem > * {
  position: relative; 
  z-index: 2; 
}

.vocalbreath-stimmcoaching {
  position: relative;
  background-image: url("/public/assets/imgs/dust1.jpg");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

.vocalbreath-stimmcoaching::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4); 
  z-index: 1; 
}

.vocalbreath-stimmcoaching > * {
  position: relative; 
  z-index: 2; 
}

.vocalbreath-image {
  max-width: 100%; 
  height: auto; 
  margin-bottom: 20px;
  border-radius: 10px; 
}

.vocalbreath-atem h1,
.vocalbreath-stimmcoaching h1 {
  text-align: center; 
  margin-bottom: 100px;
  font-size: 2.5rem;
  color: #333;
}

.vocalbreath-atem h3 {
  margin-top: 50px;
  margin-bottom: 20px;
  padding-left: 100px;
  color: goldenrod; 
}

.vocalbreath-atem p,
.vocalbreath-stimmcoaching p {
  font-size: 1rem;
  color: #444;
}

.vocalbreath-atem p {
  padding-left: 100px;
  text-align: left; 
  
}

.vocalbreath-stimmcoaching p {
  line-height: 1.5;
  padding-right: 100px;
  text-align: left;
}

@media (max-width: 768px) {

  .vocalbreath-atem {
    position: relative;
    background-image: url("/public/assets/imgs/dust2.jpg");
    background-size: cover; 
    background-position: top left; 
  }

  .vocalbreath-atem::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4); 
    z-index: 1; 
  }
  
  .vocalbreath-atem > * {
    position: relative; 
    z-index: 2; 
  }

  .vocalbreath-atem h1,
  .vocalbreath-stimmcoaching h1 {
    text-align: center; 
    margin-bottom: 50px;
    font-size: 1.8rem;
  }

  .vocalbreath-atem p {
    padding-left: 0;
    padding-top: 15px;
    text-align: center; 
  }

  .vocalbreath-atem h3 {
    padding-left: 0; 
    text-align: center;
  }

  .vocalbreath-stimmcoaching {
    position: relative;
    background-image: url("/public/assets/imgs/dust2.jpg");
    background-size: cover; 
    background-position: top left; 
  }

  .vocalbreath-stimmcoaching::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4); 
    z-index: 1; 
  }
  
  .vocalbreath-stimmcoaching > * {
    position: relative; 
    z-index: 2; 
  }

  .vocalbreath-stimmcoaching .row {
    flex-direction: column-reverse; 
    align-items: center;
  }

  .vocalbreath-stimmcoaching p {
    padding: 0; 
    text-align: center;
  }

  .vocalbreath-stimmcoaching-img {
    margin-bottom: 35px; 
  }

}

/* fascial page */
  

.fascialmanuvers {
  position: relative;
  padding: 100px 0 0;
  background-image: url("/public/assets/imgs/dust1.jpg");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

.fascialmanuvers::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4); 
  z-index: 1; 
}

.fascialmanuvers > * {
  position: relative; 
  z-index: 2; 
}

.fascialmanuvers h1 {
  text-align: center; 
  margin-bottom: 100px;
  font-size: 2.5rem;
  color: #333;
}

.fascialmanuvers p {
  line-height: 1.5;
  font-size: 1rem;
  color: #444;
  padding-right: 100px;
  text-align: left;
}


.fascial-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px; 
}

@media (max-width: 768px) {

.fascialmanuvers {
  position: relative;
  background-image: url("/public/assets/imgs/dust2.jpg");
    background-size: cover; 
    background-position: top left; 
}

.fascialmanuvers::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4); 
  z-index: 1; 
}

.fascialmanuvers > * {
  position: relative; 
  z-index: 2; 
}

  .fascialmanuvers .row {
    flex-direction: column-reverse; 
    align-items: center;
  }

  .fascialmanuvers p {
    padding: 0; 
    text-align: center;
  }

  .fascialmanuvers h1 {
    margin-bottom: 50px;
    font-size: 1.8rem;
  }

  .fascial-img {
    margin-bottom: 35px; 
  }

}

/* tre page */

.tre-section {
  position: relative;
  padding: 100px 0 0;
  background-image: url("/public/assets/imgs/dust1.jpg");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

.tre-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4); 
  z-index: 1; 
}

.tre-section > * {
  position: relative; 
  z-index: 2; 
}

.tre-section h1 {
  text-align: center;
  margin-bottom: 100px;
  font-size: 2.5rem;
  color: #333;
}

.tre-section h3 {
  margin-top: 50px;
  margin-bottom: 20px;
  padding-left: 100px;
  color: goldenrod;
}

.tre-section h4 {
  margin-top: 25px;
  margin-bottom: 20px;
  padding-left: 100px;
}

.tre-section p {
  font-size: 1rem;
  color: #444;
  padding-left: 100px;
  text-align: left;
}

.tre-video {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}

.tre-image-wrapper {
  position: relative; 
  margin-top: 20px;
  border-radius: 10px;
}

@media (max-width: 768px) {

  .tre-section {
    position: relative;
    background-image: url("/public/assets/imgs/dust2.jpg");
    background-size: cover; 
    background-position: top left; 
  }

  .tre-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4); 
    z-index: 1; 
  }
  
  .tre-section > * {
    position: relative; 
    z-index: 2; 
  }

  .tre-section h1 {
    margin-bottom: 50px;
    font-size: 1.8rem;
  }

  .tre-section h3 {
    padding-left: 0; 
    text-align: center;
  }

  .tre-section h4 {
    padding-left: 0; 
    text-align: center;
  }

  .tre-section p {
    padding: 0; 
    text-align: center;
  }

  .tre-image-wrapper {
    position: absolute;
    bottom: -100px; 
    left: 70px;
    right: 70px;
    text-align: center;
    max-width: 200px;
    padding: 50px;
  }
}

/* familyconst page */

.familyconst {
  position: relative;
  padding: 100px 0 0;
  background-image: url("/public/assets/imgs/dust1.jpg");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

.familyconst::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4); 
  z-index: 1; 
}

.familyconst > * {
  position: relative; 
  z-index: 2; 
}

.familyconst h1 {
  text-align: center; 
  margin-bottom: 100px;
  font-size: 2.5rem;
  color: #333;
}

.familyconst p {
  line-height: 1.5;
  font-size: 1rem;
  color: #444;
  padding-right: 100px;
  text-align: left;
}

.familyconst-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}

@media (max-width: 768px) {

  .familyconst {
    position: relative;
    background-image: url("/public/assets/imgs/dust2.jpg");
    background-size: cover; 
    background-position: top left; 
  }

  .familyconst::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4); 
    z-index: 1; 
  }
  
  .familyconst > * {
    position: relative; 
    z-index: 2; 
  }

  .familyconst .row {
    flex-direction: column-reverse; 
    align-items: center;
  }

  .familyconst p {
    padding: 0; 
    text-align: center;
  }

  .familyconst h1 {
    margin-bottom: 50px;
    font-size: 1.5rem;
    text-align: center;
  }

  .familyconst-image {
    margin-bottom: 35px; 
  }

}

/* Footer styles */

footer {
  background: transparent;
  color: black;
  text-align: center;
  padding: 20px 0;
  font-size: 15px;
  text-decoration: solid;
  margin-top: 80px; 
}

footer .footer-brand {
  font-weight: bold; 
  font-size: 20px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.footer-brand {
  display: flex;
  align-items: center; 
  gap: 1px; 
}

.footer-icons {
  display: flex;
  gap: 2px; 
}

.footer-icons img {
  width: 25px;
  height: 25px;
  
}

.footer-text {
  margin-top: 10px;
  font-size: 15px;
  color: black;
}

.footer-link {
  color: gold;
}

@media (max-width: 576px) {
  footer {
    font-size: 12px; 
    margin-top: 50px; 
  }

  footer .footer-brand {
    font-size: 16px; 
  }

  .footer-icons img {
    width: 20px; 
    height: 20px;  
  }

  .footer-text {
    font-size: 12px; 
  }

  .footer-text a {
    font-size: 12px; 
  }
}
.privacy-policy {
  position: relative;
background-image: url("/public/assets/imgs/dust2.jpg");
}

.privacy-policy::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4); 
  z-index: 1; 
}

.privacy-policy > * {
  position: relative; 
  z-index: 2; 
}
.privacy-policy-container {
  padding: 100px 20px;
  max-width: 800px;
  margin: 0 auto;
}