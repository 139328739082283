
.top-area {
    position: relative;
    z-index: 9999;
}

nav.navbar {
    background-color: #fff;
    border: none;
    box-shadow: 0 3px 15px rgba(0, 0, 0, .2);
    position: fixed; 
    top: 0; 
    width: 100%;
    z-index: 1000;
}

.navbar-brand {
    display: inline-block;
    color: goldenrod;
    font-size: 22px;
    font-weight: 600;
    padding: 20px 0px 30px;
    text-transform: uppercase;
}

.navbar-collapse {
    justify-content: flex-end;
}

.navbar-nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.navbar-nav > li {
    margin-right: 10px;
    list-style: none;
}

.navbar-nav > li > a {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 5px 20px; 
    transition: color 0.3s linear;
}

.navbar-nav > li > a:hover,
.navbar-nav > li.active > a {
    color: goldenrod;
}

.navbar-toggler {
    background-color: transparent;
    border: 1px solid goldenrod;
    padding: 10px;
}

.navbar-toggler i {
    color: goldenrod;
}

.smooth-menu {
    scroll-behavior: smooth;
}

.navbar-images .navbar-img {
    width: 22px; 
    height: 22px; 
    margin-left: 2px;
}



